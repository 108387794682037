// 查看课程明细的弹框
<template>
  <div class="look_account_detail_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="dialog_content">
          <!-- 内容视图 -->
          <div class="dv_table_view_content" v-if="showLoading == 1">
            <el-table border stripe :data="tableData" max-height="370">
              <!-- 序号 -->
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="80"
              >
              </el-table-column>

              <!-- 时间 -->
              <el-table-column
                prop="create_time"
                align="center"
                label="时间"
                width="130"
              ></el-table-column>

              <!-- 操作  -->
              <el-table-column label="操作" align="center" width="120">
                <template scope="scope">
                  <!-- 新增 type==1 -->
                  <div v-if="scope.row.type == 1">
                    <span class="tv_red">+新增</span>
                  </div>

                  <!-- 消耗 type==0 -->
                  <div v-else-if="scope.row.type == 0">
                    <span class="tv_green">-消耗</span>
                  </div>

                  <!-- 失效 type==2 -->
                  <div v-else-if="scope.row.type == 2">
                    <span class="tv_red">失效</span>
                  </div>

                  <!-- 恢复 type==3 -->
                  <div v-else-if="scope.row.type == 3">
                    <span class="tv_red">恢复</span>
                  </div>

                  <!-- 修改期限 type==4 -->
                  <div v-else-if="scope.row.type == 4">
                    <span class="tv_red">修改期限</span>
                  </div>
                </template>
              </el-table-column>

              <!-- 操作描述  -->
              <el-table-column align="center" label="操作描述" min-width="20%">
                <template scope="scope">
                  <!-- 新增 type==1 -->
                  <div class="tv_small" v-if="scope.row.type == 1">
                    <span>新增了{{ scope.row.num }}个</span
                    ><span class="tv_red"
                      >{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{
                      scope.row.end_date
                    }}之前有效)
                  </div>

                  <!-- 消耗 type==0 -->
                  <div class="tv_small" v-else-if="scope.row.type == 0">
                    <span>消耗了{{ scope.row.num }}个</span
                    ><span class="tv_red"
                      >{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{
                      scope.row.end_date
                    }}之前有效)
                  </div>

                  <!-- 失效 type==2 -->
                  <div class="tv_small" v-else-if="scope.row.type == 2">
                    <span class="tv_red"
                      >{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >
                    <span>失效了{{ scope.row.num }}个</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{
                      scope.row.end_date
                    }}之前有效)
                  </div>

                  <!-- 恢复 type==3 -->
                  <div class="tv_small" v-else-if="scope.row.type == 3">
                    <span>恢复了</span
                    ><span class="tv_red"
                      >{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >
                    <span>{{ scope.row.num }}个</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{
                      scope.row.end_date
                    }}之前有效)
                  </div>

                  <!-- 修改 type==4 -->
                  <div class="tv_small" v-else-if="scope.row.type == 4">
                    <span>修改了</span>
                    <span class="tv_red"
                      >{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    ><span>{{ scope.row.num }}个</span
                    >{{
                      scope.row.role == 0 ? "学生" : "老师"
                    }}账号的使用期限(由{{ scope.row.old_start_date }}~{{
                      scope.row.old_end_date
                    }}修改为{{ scope.row.start_date }}~{{ scope.row.end_date }})
                  </div>
                </template>
              </el-table-column>

              <!-- 操作人  -->
              <el-table-column align="center" label="操作人" width="150">
                <template scope="scope">
                  <span>{{
                    scope.row.operator_name ? scope.row.operator_name : "-"
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 加载视图 -->
          <Loading v-if="showLoading == -1"></Loading>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //显示加载布局的标识
      showLoading: -1,

      //弹框快递
      width: "820px",

      //表格的最大高度
      maxHeight: 0,

      //表格数据
      tableData: [],
    };
  },
  created() {
    this.httpData();
  },
  methods: {
    //关闭的方法
    close() {
      this.$emit("close");
    },

    //请求数据
    httpData() {
      let param = this.getHttpParams();
      param.type = 7;
      param.school_id = this.schoolId;
      param.pid = this.pid;
      param.sub_id = this.subId;
      param.level_id = this.levelId;
      this.$http
        .fetchPost(this.$api.SCHOOL_MANAGER, param)
        .then((res) => {
          // console.log("返回的数据是:", res);
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          if (result.state == 200) {
            let serverArr = result.data.data_list;
            if (!this.isEmpty(serverArr)) {
              serverArr.forEach((item, index) => {
                //序号
                item.index = index + 1;

                this.tableData.push(item);
              });
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
  },
  computed: {},
  components: {},

  props: ["showDialog", "title", "pid", "subId", "schoolId", "levelId"],
};
</script>
<style lang="scss" scoped>
.look_account_detail_dialog {
  .dialog_content {
    height: 50vh;
    margin: 10px 0;
    display: flex;
    align-items: center;

    //表格内容部分
    .dv_table_view_content {
      margin: 0 auto;
      height: 100%;
      width: 100%;

      .tv_small {
        font-size: 12px;
      }
    }
  }
}
</style>
